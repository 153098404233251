<template>
    <div class="UserAccount">
        <PromotionTime></PromotionTime>
        <div class="bxProfileColumn">
            <div class="panel-box">
                <div class="scrolling-wrapper-flexbox-card">
                    <div class="panel-box-title">
                        <h4>บัญชีธนาคาร</h4>
                        <router-link :to="{name:'DepositWithdraw'}" class="btn-stm btn-stm-DepositWithdraw">
                            <span>ฝาก - ถอน</span>
                        </router-link>
                    </div>
                    <div class="bx-btnAddbankAccount">
                        <button @click="toCreateBankAccount" class="btn btn-outline-primary" style="right: 10px;">
                        <span class="icon"><i class="fas fa-plus"></i>
                            เพิ่มบัญชี</span>
                        </button>
                    </div>
                    <carousel
                            :per-page="1"
                            :mouseDrag="true"
                            :navigationEnabled="true"
                            :centerMode="true"
                            :paginationEnabled="false"
                            navigationNextLabel="❯"
                            navigationPrevLabel="❮"
                    >
                        <slide v-for="account in account_list" :key="account.id" >
                            <div class="panel-block-card">
                                <div class="box-name-bank">
                                    <div>{{account.bank_name | bank_display}}</div>
                                </div>
                                <div class="box-number-account">
                                    <h6>หมายเลขบัญชี</h6>
                                    <div>{{account.account_no || '-'}}</div>
                                </div>
                                <div class="box-name-account">
                                    <h6>ชื่อบัญชี</h6>
                                    <div>{{account.account_name || '-'}}</div>
                                </div>
                                <span :class="account.bank_name | bank_icon_class" class="bank-logo-s">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </span>
                            </div>
                        </slide>
                    </carousel>

                </div>
                <div class="bx-alert">
                    <ul>
                        <li>โปรดระมัดระวังการเพิ่มข้อมูลบัญชีธนาคาร</li>
                        <li>เมื่อเพิ่มแล้วไม่สามารถเปลี่ยนแปลงได้</li>
                        <li>ชื่อ-นามสกุล ต้องตรงกับบัญชีที่จะถอนเงิน</li>
                        <li>เพิ่มบัญชีได้ไม่เกิน 4 บัญชี</li>
                        <li>บัญชีที่เพิ่มเข้ามาต้องเป็นชื่อ-สกุลเดียวกันทั้งหมด</li>
                    </ul>
                </div>
            </div>
        </div>


        <!-- <div class="content-show-user" style="margin-top: 10px;">
            <div class="panel-box-title">
                <h4><i class="fas fa-address-card"></i> โปรไฟล์</h4>
            </div>
            <div class="panel-block" style="flex-direction: column; align-items: flex-start;">
                <div class="field" style="width: 100%; margin-bottom: 10px;">
                    <div class="control">
                        <div class="v-wrapper">
                            <span class="left">ชื่อ</span>
                            
                            <p v-show="!isShowEditDisplayName">{{user_profile.display_name}}</p>
                            <el-button v-show="!isShowEditDisplayName" @click="toEditDisplayName" type="text" style="margin-left: 10px;">แก้ไข</el-button>

                            <p v-show="isShowEditDisplayName"><input v-model="display_name" type="text" class="form-control"></p>
                            <el-button v-show="isShowEditDisplayName" @click="confirmEditDisplayName" type="text" style="margin-left: 10px;">ตกลง</el-button>
                            <el-button v-show="isShowEditDisplayName" @click="cancelEditDisplayName" type="text" style="margin-left: 10px;">ยกเลิก</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="content-show-user">
            <div class="panel-box-title">
                <h4>ข้อมูลส่วนตัว</h4>
            </div>
            <div class="panel-block" style="flex-direction: column; align-items: flex-start;">
                <div class="field" style="width: 100%; margin-bottom: 10px;">
                    <div class="control">
                        <div class="v-wrapper">
                            <span class="left">ชื่อ</span>
                            <p>{{user_profile.first_name}}</p>
                        </div>
                    </div>
                </div>
                <div class="field" style="width: 100%;">
                    <div class="control">
                        <div class="v-wrapper">
                            <span class="left">นามสกุล</span>
                            <p style="margin-left: 70px;">{{user_profile.last_name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="bxProfileColumn">
            <div class="content-show-user">

                <div class="panel-box-title panel-box-title-pes">
                    <h4>บัญชี PES</h4>
                    <button @click="toggleSetPassword" class="btn btn-outline-primary">
                        เปลี่ยนรหัสผ่าน
                    </button>
                </div>
                <div class="panel-block" style="flex-direction: column; align-items: flex-start;">
                    <div class="is-full">
                        <div class="field">
                            <div class="control">
                                <div class="v-wrapper">
                                    <span class="left"><i class="fas fa-user"></i></span>
                                    <p>{{user_profile.pes_username}}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="!user_profile.is_pes_set_password" class="field" style="margin-top: 10px;">
                            <div class="control">
                                <div class="v-wrapper">
                                    <span class="left"><i class="fas fa-unlock-alt"></i></span>
                                    <p v-show="isShowPassword">{{user_profile.pes_password}}</p>
                                    <p v-show="!isShowPassword">......</p>
                                    <span v-show="!isShowPassword" @click="isShowPassword = true" class="right">
                                    <i class="fas fa-eye"></i>
                                </span>
                                    <span v-show="isShowPassword" @click="isShowPassword = false" class="right">
                                    <i class="far fa-eye-slash"></i>
                                </span>
                                </div>
                                <div class="ps-noti">
                                    *** password สำหรับเข้าใช้งานครั้งแรก เพื่อความปลอดภัย กรุณาตั้ง password ใหม่ ***
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <div class="is-center is-full" style="text-align: center; margin-top: 10px;">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isLineSyncAvailable">
                <br>
                <div class="content-show-user">

                    <div class="panel-box-title panel-box-title-pes">
                        <h4>เชื่อมต่อบัญชี</h4>
                    </div>
                    <div class="panel-block" style="flex-direction: column; align-items: flex-start;">
                        <div class="is-full">
                            <div class="field">
                                <div class="control">
                                    <div class="v-wrapper" style="padding: 10px 0;">
                                        <LineLoginButton mode="sync"></LineLoginButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <br>
            </div>

            <div class="field-btn-signOut">
                <el-button type="text" @click="goSignOut">ลงชื่อออก</el-button>
            </div>
        </div>


        <b-modal ref="bank-account-modal" modal-class="editModal editModal2"
        hide-footer 
        centered 
        title="เพิ่มบัญชีธนาคาร"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close>
<!--            <div class="form-row">-->
<!--                ธนาคาร-->
<!--            </div>-->

            <div class="form-row">
                <select v-model="account.bank_name" class="form-control">
                    <option value="">--เลือกธนาคาร--</option>
                    <option v-for="bank in bank_list" :key="bank.swift_code" :value="bank.swift_code">{{bank.swift_code | bank_display}}</option>
                </select>
            </div>
            <div>
                <div class="form-row">
                    เลขที่บัญชี (ตัวเลข)
                </div>
                <div class="form-row">
                    <input v-model="account.account_no" class="form-control" type="text">
                </div>
                <div v-show="!verifyPreviewAccountStateIdle && false" class="form-row">
                    <input v-model="account.account_name" class="form-control" :class="{'bank-account-verify-fail': verifyPreviewAccountStateFail}" type="text" disabled>
                </div>
                <div class="form-row formRowBtn">
                    <button @click="createVerifyAccount" class="btn btn-block btn-success" :disabled="!(verifyPreviewAccountStateSucess || verifyPreviewAccountStateFail)">
                        <span v-if="verifyPreviewAccountStateProgress">กำลังตรวจสอบข้อมูล</span>
                        <span v-else>ตกลง</span>
                    </button>
                </div>
            </div>
            <!-- <div v-if="isOnDev">
                <div class="form-row">
                    เลขที่บัญชี (ตัวเลข)
                </div>
                <div class="form-row">
                    <input v-model="account.account_no" class="form-control" type="text">
                </div>
                <div v-show="!verifyPreviewAccountStateIdle" class="form-row">
                    <input v-model="account.account_name" class="form-control" :class="{'bank-account-verify-fail': verifyPreviewAccountStateFail}" type="text" disabled>
                </div>
                <div class="form-row formRowBtn">
                    <button @click="createVerifyAccount" class="btn btn-block btn-success" :disabled="!(verifyPreviewAccountStateSucess || verifyPreviewAccountStateFail)">
                        <span v-if="verifyPreviewAccountStateProgress">กำลังตรวจสอบข้อมูล</span>
                        <span v-else>ตกลง</span>
                    </button>
                </div>
            </div>
            <div v-else>
                <div class="form-row">
                    <input v-model="account.account_no" class="form-control" placeholder=" เลขที่บัญชี (ตัวเลข)" type="text">
                </div>
                <div class="form-row formRowBtn">
                    <button @click="createAccount" class="btn btn-block btn-success">ตกลง</button>
                </div>
            </div> -->
            <!-- <div>
                <div class="form-row">
                    <input v-model="account.account_no" class="form-control" placeholder=" เลขที่บัญชี (ตัวเลข)" type="text">
                </div>
                <div class="form-row formRowBtn">
                    <button @click="createAccount" class="btn btn-block btn-success">ตกลง</button>
                </div>
            </div> -->
            <!-- <div>
                <div class="form-row">
                    ชื่อบัญชี (ภาษาไทย)
                </div>
                <div class="form-row">
                    <input v-model="user_full_name" class="form-control" type="text" disabled>
                </div>
                <div class="form-row">
                    เลขที่บัญชี (ตัวเลข)
                </div>
                <div class="form-row">
                    <button @click="$refs['bank-account-modal'].hide()" class="btn btn-block btn-light">ยกเลิก</button>
                </div>
            </div> -->
        </b-modal>

        <b-modal @hidden="cancelSetPassword" ref="change-password-modal" hide-footer centered title="เปลี่ยนรหัสผ่าน">
            <div class="form-row">
                รหัสผ่านปัจจุบัน
            </div>
            <div class="form-row">
                <input v-model="old_password" class="form-control" type="password">
            </div>
            <div class="form-row">
                รหัสผ่านใหม่
            </div>
            <div class="form-row">
                <input v-model="new_password" class="form-control" type="password">
            </div>
            <div class="form-row">
                ยืนยันรหัสผ่านใหม่
            </div>
            <div class="form-row">
                <input v-model="confirm_password" class="form-control" type="password">
            </div>
            <div class="form-row">
                <button @click="changePassword" class="btn btn-block btn-primary">ตกลง</button>
                <button @click="hideSetPasswordModal" class="btn btn-block btn-light">ยกเลิก</button>
            </div>
        </b-modal>

        <b-modal ref="go-create-account-modal"
                 modal-class="editModal" button-size="lg"
        hide-header
        centered
        ok-only 
        no-close-on-backdrop
        no-close-on-esc
        ok-title="ตกลง"
        @ok="toCreateBankAccount">
            <div class="style-modal modal-notify-text-center">
                <i class="fas fa-check-circle" style="color: #0b6b2d; margin-top: 15px;"></i>
                <h5>เพิ่มเลขบัญชีเพื่อให้สามารถฝากถอนเงินได้</h5>
            </div>
        </b-modal>

        <b-modal ref="first-last-name-modal" hide-footer centered title="เพิ่มข้อมูลส่วนตัว">
            <div>
                <div style="font-size: 14px; width: 100%;">
                    <b-alert variant="primary" show>กรุณาเพิ่ม ชื่อ - นามสกุล เพื่อดำการต่อ</b-alert>
                </div>
            </div>
            <div class="form-row">
                ชื่อ (ภาษาไทย)
            </div>
            <div class="form-row">
                <input v-model="userInfo.first_name" class="form-control" type="text">
            </div>
            <div class="form-row">
                นามสกุล (ภาษาไทย)
            </div>
            <div class="form-row">
                <input v-model="userInfo.last_name" class="form-control" type="text">
                <div style="font-size: 12px; padding-top: 4px; width: 100%;">
                    <b-alert variant="danger" show>*** ชื่อ-นามสกุล ต้องตรงกับบัญชีที่จะถอนเงิน ***</b-alert>
                </div>
            </div>
            <div class="form-row">
                <button @click="addFirstLastName" class="btn btn-block btn-success">ตกลง</button>
            </div>
            <div class="form-row">
                <button @click="$refs['first-last-name-modal'].hide()" class="btn btn-block btn-light">ยกเลิก</button>
            </div>
        </b-modal>

        <b-modal ref="add-mobile-modal" hide-footer centered title="เพิ่มข้อมูลส่วนตัว">
            <div>
                <div style="font-size: 14px; width: 100%;">
                    <b-alert variant="primary" show>กรุณาเพิ่ม หมายเลขโทรศัพท์ เพื่อดำการต่อ</b-alert>
                </div>
                <div>
                    <b-alert variant="danger" show>*** หมายเลขโทรศัพท์ จะมีการ verify เมื่อมีการแจ้งถอน ถ้า verify ไม่ผ่าน จะไม่สามารถถอนเงินได้ ***</b-alert>
                </div>
            </div>
            <div>
                <div class="form-row">
                    หมายเลขโทรศัพท์
                </div>
                <div class="form-row">
                    <input v-model="addMobilePhoneNumberForm.mobile_phone_number" class="form-control" type="text" placeholder="08XXXXXXXX">
                </div>
                <div class="form-row">
                    <button @click="addMobilePhoneNumberNoOtp" type="button" class="btn btn-primary btn-block">เพิ่มหมายเลขโทรศัพท์</button>
                </div>
            </div>
            <div class="form-row">
                <button @click="$refs['add-mobile-modal'].hide()" class="btn btn-block btn-light">ยกเลิก</button>
            </div>
        </b-modal>
    </div>
</template>
<script>

import axiosWrapper from '@/axiosWrapper'
import store from '@/store'
import {BANK_LIST} from '@/constant'
import debounce from '@/debounce'
import UserMixin from '@/mixins/UserMixin'
import AccountMixin from '@/components/user/AccountMixin'
import { Carousel, Slide } from 'vue-carousel'
import LineLoginButton from '@/components/user/LineLoginButton'
import PromotionTime from '@/components/user/PromotionTime'

export default {
    name: 'UserAccount',
    components: {
        Carousel,
        Slide,
        LineLoginButton,
        PromotionTime,
    },
    mixins: [
        UserMixin,
        AccountMixin,
    ],
    data() {
        return {
            bank_list: [],
            account_list: [],
            account: {
                bank_name: '',
                account_name: '',
                account_no: '',
            },
            loading: null,
            isShowPassword: false,
            old_password: '',
            new_password: '',
            confirm_password: '',
            userInfo: {
                first_name: '',
                last_name: '',  
            },
            addMobilePhoneNumberForm: {
                mobile_phone_number: '',
            },
            verifyPreviewAccountState: 'idle', //idle, progress, success, fail
        }
    },
    computed: {
        pes_profile() {
            return store.state.pes_profile
        },
        user_profile() {
            return store.state.user_profile
        },
        toCreateAccountQuery() {
            return this.$route.query['to_create_account']
        },
        packetId(){
            return this.$route.query['packet_id']
        },
        isLineSyncAvailable() {
            return process.env.NODE_ENV === 'development' || window.location.hostname === 'chat.liffline.com' || window.location.hostname.includes('ngrok')
        },
        user_first_name() {
            return (this.user_profile) ? this.user_profile.first_name : ''
        },
        user_last_name() {
            return (this.user_profile) ? this.user_profile.last_name : ''
        },
        user_full_name() {
            return `${this.user_first_name} ${this.user_last_name}`
        },
        verifyPreviewAccountStateIdle() {
            return this.verifyPreviewAccountState === 'idle'
        },
        verifyPreviewAccountStateProgress() {
            return this.verifyPreviewAccountState === 'progress'
        },
        verifyPreviewAccountStateSucess() {
            return this.verifyPreviewAccountState === 'success'
        },
        verifyPreviewAccountStateFail() {
            return this.verifyPreviewAccountState === 'fail'
        },
    },
    watch: {
        'account.bank_name': function(newVal) {
            // if(this.isOnDev) {
                if(newVal) {
                    this.verifyPreviewAccount()
                }
            // }
        },
        'account.account_no': debounce( function(newVal) {
            // if(this.isOnDev) {
                if(newVal) {
                    this.verifyPreviewAccount()
                }
            // }
        }, 400),
    },
    methods: {
        goBackToPurchasePacket(){
            if(this.packetId &&  !sessionStorage.getItem('new_member_register')){
                sessionStorage.setItem('new_member_register',1)
                sessionStorage.setItem('is_packet_show',2)
                location.reload();
            }
            
        },
        clearAccount() {
            this.account.bank_name = ''
            this.account_no = ''
        },
        toCreateBankAccount() {
            this.clearAccount()
            if(!this.user_profile.mobile_phone_number) {
                this.$refs['add-mobile-modal'].show()
                return
            }
            this.$refs['bank-account-modal'].show()
        },
        async getAccountList() {

            this.loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_account/get_by_id_user`,
                data: {
                    token: this.pes_profile.token
                }
            });
            if(res.status === 200){
                this.account_list = res.data;
                if(!(this.account_list.length > 0)){
                    if(this.toCreateAccountQuery === 'yes') {
                        this.toCreateBankAccount()
                    } else {
                        this.$refs['go-create-account-modal'].show()
                    }
                }
            }else if(res.status === 400){
                this.$bvToast.toast(res.data.message, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }else{
                this.$bvToast.toast('เกิดปัญหาบางอย่าง กรุณาลองอีกครั้ง', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }

            this.loading.close()
        },
        toggleSetPassword() {
            this.clearPassword()
            this.$refs['change-password-modal'].show()
        },
        cancelSetPassword() {
            if(this.$route.query['change-password'] === 'yes') {
                this.$router.back()
            }
        },
        hideSetPasswordModal() {
            this.$refs['change-password-modal'].hide()
        },
        createAccount: debounce(async function() {

            if(!this.account.account_no || !this.account.bank_name){
                this.$bvToast.toast('กรุณากรอกข้อมูลให้ครบถ้วน', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return;
            }

            this.loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_account/create`,
                data: {
                    token: this.pes_profile.token,
                    bank_name: this.account.bank_name,
                    account_no: this.account.account_no,
                }
            });

            this.loading.close()

            if(res.status === 200){
                
                this.$bvToast.toast('เพิ่มบัญชีสำเร็จ', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                this.$refs['bank-account-modal'].hide()
                this.clearAccount()
                this.getAccountList();
                this.goBackToPurchasePacket();
                
            }else{
                this.$bvToast.toast(res.data.message, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }
        }, 400),
        async verifyPreviewAccount() {
            
            if(!this.account.bank_name) {
                return
            }

            let bankAccountNumberRequireLength = 10

            if(this.account.bank_name === 'GSBATHBK') {
                bankAccountNumberRequireLength = 12
            }

            if(this.account.account_no.length < bankAccountNumberRequireLength) {
                this.verifyPreviewAccountState = 'idle'
                this.account.account_name = ''
                return
            }

            this.account.account_name = ''
            this.verifyPreviewAccountState = 'success'
            return
            // const bankInfo = this.bank_list.find((bank) => {
            //     return bank.swift_code === this.account.bank_name
            // })

            // const res = await axiosWrapper({
            //     method: 'POST',
            //     url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_account/verify`,
            //     data: {
            //         token: this.pes_profile.token,
            //         bank_name: this.account.bank_name,
            //         account_no: this.account.account_no,
            //         bank_code: bankInfo.bank_code,
            //     }
            // });

            // if(res.status === 200){
            //     this.account.account_name = res.data.account_name
            //     this.verifyPreviewAccountState = 'success'
            // }else{
            //     // this.account.account_name = 'คุณใส่เลขบัญชีไม่ถูกต้องกรุณาใส่ใหม่อีกครั้ง'
            //     // this.account.account_name = res.data.message
            //     this.account.account_name = 'ไม่สารถแสดงชื่อบัญชีได้เลขบัญชีอาจไม่ถูกต้อง'
            //     this.verifyPreviewAccountState = 'fail'
            // }
        },
        createVerifyAccount: debounce(async function() {

            if(!this.account.account_no || !this.account.bank_name){
                this.$bvToast.toast('กรุณากรอกข้อมูลให้ครบถ้วน', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return;
            }

            if(!(this.verifyPreviewAccountStateSucess || this.verifyPreviewAccountStateFail)) {
                this.$bvToast.toast('คุณใส่เลขบัญชีไม่ถูกต้องกรุณาใส่ใหม่อีกครั้ง', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return;
            }

            this.loading = this.$loading()

            const bankInfo = this.bank_list.find((bank) => {
                return bank.swift_code === this.account.bank_name
            })

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_account/create-verify`,
                data: {
                    token: this.pes_profile.token,
                    bank_name: this.account.bank_name,
                    account_no: this.account.account_no,
                    bank_code: bankInfo.bank_code,
                }
            });

            this.loading.close()

            if(res.status === 200){
                
                this.$bvToast.toast('เพิ่มบัญชีสำเร็จ', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                this.$refs['bank-account-modal'].hide()
                this.clearAccount()
                this.getAccountList();
                this.goBackToPurchasePacket();
                
            }else{
                this.$bvToast.toast(res.data.message, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }
        }, 400),
        clearPassword() {
            this.old_password = '';
            this.new_password = '';
            this.confirm_password = '';
        },
        changePassword: debounce(async function() {

            if(!this.old_password || !this.new_password || !this.confirm_password){
                this.$bvToast.toast('กรุณากรอกข้อมูลให้ครบถ้วน', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return;
            }

            if(this.new_password !== this.confirm_password){
                this.$bvToast.toast('รหัสผ่านใหม่ไม่ตรงกัน', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return;
            }

            this.loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/password/update`,
                data: {
                    password: this.old_password,
                    new_password: this.new_password,
                    token: this.pes_profile.token,
                    user_id: this.user_profile.user_id
                }
            });
            if (res.status === 200) {
                this.$bvToast.toast('เปลี่ยนรหัสผ่านสำเร็จ', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                this.clearPassword()
                this.hideSetPasswordModal()
                this.requestUserReload()
            } else if (res.status === 400) {
                this.$bvToast.toast(res.data.message, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            } else {
                this.$bvToast.toast('เกิดปัญหาบางอย่าง กรุณาลองอีกครั้ง', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }
            this.loading.close()
        }, 400),
        requestUserReload() {
            store.commit('updateNeedUserReload', true)
        },
        goSignOut() {
            this.signOut()
            setTimeout(() => {
                location.reload()
            }, 100)
        },
        addFirstLastName: debounce(async function() {

            if(!this.userInfo.first_name || !this.userInfo.last_name){
                this.$bvToast.toast('กรุณากรอกข้อมูลให้ครบถ้วน', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return;
            }

            this.loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/add-first-last-name`,
                data: {
                    token: this.pes_profile.token,
                    ...this.userInfo
                }
            });

            this.loading.close()

            if(res.status === 200){
                this.requestUserReload()
                this.$bvToast.toast('เพิ่มข้อมูลส่วนตัวสำเร็จ', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                this.$refs['first-last-name-modal'].hide()

                if(!this.user_profile.mobile_phone_number) {
                    this.$refs['add-mobile-modal'].show()
                    return
                }

                this.$refs['bank-account-modal'].show()
            }else{
                this.$bvToast.toast(res.data.message, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }
        }, 400),
        addMobilePhoneNumber: debounce(async function(data) {

            const token = this.pes_profile.token
            const {mobile_phone_number, otp} = data

            this.loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/add-mobile-phone-number`,
                data: {
                    token: token,
                    mobile_phone_number: mobile_phone_number,
                    otp: otp,
                }
            });

            this.loading.close()

            if(res.status === 200){
                this.requestUserReload()
                this.$bvToast.toast('เพิ่มหมายเลขโทรศัพท์สำเร็จ', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                this.$refs['add-mobile-modal'].hide()

                this.$refs['bank-account-modal'].show()
            }else{
                this.$bvToast.toast(res.data.message, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }
        }, 400),
        addMobilePhoneNumberNoOtp: debounce(async function() {

            const token = this.pes_profile.token
            const mobile_phone_number = this.addMobilePhoneNumberForm.mobile_phone_number

            if(!mobile_phone_number) {
                return
            }

            this.loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/user/add-mobile-phone-number-no-otp`,
                data: {
                    token: token,
                    mobile_phone_number: mobile_phone_number,
                }
            });

            this.loading.close()

            if(res.status === 200){
                this.requestUserReload()
                this.$bvToast.toast('เพิ่มหมายเลขโทรศัพท์สำเร็จ', {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                this.$refs['add-mobile-modal'].hide()

                this.$refs['bank-account-modal'].show()
            }else{
                this.$bvToast.toast(res.data.message, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
            }
        }, 400),
    },
    created() {
        this.bank_list = BANK_LIST
    },
    mounted() {
        this.getAccountList()
        console.log('packet_id',this.packetId);
        if(this.$route.query['change-password'] === 'yes') {
            this.toggleSetPassword()
        }
        // this.goBackToPurchasePacket()//for test
//         var self = this;
//         setTimeout(function(){
// self.goBackToPurchasePacket();
//         },1000*3);
        
    }
}
</script>
<style src="@/assets/css/obs-liff.css" scoped></style>
<style>
    .UserAccount .contentFloatCoupon{
        top: 0;
    }
</style>
<style scoped>
    .VueCarousel-slide{
        /*flex-basis: initial;*/
    }
    .UserAccount {
        padding: 8px 0 0 0;
    }
    .field-btn-signOut{display: none}
    .UserAccount .btn-stm{
        display: inline-block;
        font-size: 14px;
        color: #fff!important;
        background-color: #424251;
        padding: 0 15px;
        border-radius: 20px;
        height: 30px;
        line-height: 30px;
        position: relative;
        z-index: 11;
        margin-right: 10px;
    }
    .UserAccount .btn-stm-DepositWithdraw{
        background-color: #2EB82E;
    }

.form-row {
    display: flex;
    justify-content: flex-start;
    margin: 8px 4px;
}
/*.UserAccount{margin: 15px 0 15px 0;}*/
.Account .panel-box-title h4{
    font:  16px  Helvetica!important;
    margin-bottom: 0;
}
    /*.UserAccount .VueCarousel{flex: 3;width: 50%;}*/
/*.panel-box .panel-box-title{padding-right: 0!important; margin-bottom: 10px;}*/


.panel-box-title .btn-outline-primary {
    /*background-color: rgba(84, 17, 127, 0.11);*/
    /*color: #54117F;*/
    /*border-color: #54117F;*/
}
.content-show-user{margin: 0 10px 20px 10px;}
.content-show-user .v-wrapper {
    /*position: relative;*/
    /*border-radius: 20px;*/
    /*background-color: rgba(84, 17, 127, 0.11);*/
    /*padding-left: 10px;*/
    /*height: 50px;*/
    /*line-height: 50px;*/
    /*color:  rgba(84, 17, 127, 0.9);*/
}
.modal-notify-text-center {
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
}
.bank-account-verify-fail {
    color: red;
}
</style>